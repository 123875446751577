









































































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import Loading from "@/components/Loading.vue";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import PointInputVue from "./partials/payment-transactions/PointInput.vue";
import PaymentMethodInputVue from "./partials/payment-transactions/PaymentMethodInput.vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  components: { Loading },
  data() {
    return {
      self: this,
      userManager,
      loading: false,
      items: [],
      tenantSetting: {},
      point: 0,
      addPointBtnOptions: null,
    };
  },
  methods: {
    async initialize() {
      this.loading = true;
      {
        if (userManager.checkRole(["user"], true)) {
          this.loading = true;
          {
            const { point } = await coreApiClient.call("users", "findById", {
              id: userManager.getUserInfo()._id,
            });
            this.point = point || 0;
          }
          {
            this.tenantSetting = await coreApiClient.call(
              "tenants",
              "getSetting",
              {
                id: userManager.getUserInfo().tenant._id,
              }
            );
          }
          this.addPointBtnOptions = this.makeAddPointBtnOptions();
          this.loading = false;
        }
      }
      {
        const { items } = await coreApiClient.call(
          "paymentTransactions",
          "findAll",
          {
            payload: JSON.stringify({
              limit: -1,
              orderBy: "time",
              orderType: "desc",
              filters: [
                {
                  key: "status",
                  operator: "equal_to",
                  value: "success",
                },
              ],
            }),
          }
        );
        this.items = items;
      }
      this.loading = false;
    },
    makeAddPointBtnOptions() {
      return {
        attrs: {
          color: "primary",
          dark: false,
          small: true,
          class: "ml-4",
        },
        content: {
          text: "Top-up",
        },
        target: {
          dialog: {
            handlers: {
              initialize() {
                this.options.content.buttons.save.states.enabled =
                  new DataContainer(false);
              },
            },
            attrs: {
              width: "700px",
            },
            content: {
              toolbar: {
                title: "Top-up",
              },
              content: {
                type: "XForm",
                makeAttrs(attrs, dialog) {
                  attrs.xModel =
                    dialog.options.content.buttons.save.states.enabled;
                  attrs.xContext = dialog;
                  attrs.xData = new DataContainer({});
                  return attrs;
                },
                attrs: {
                  xOptions: {
                    content: {
                      sections: {
                        default: {
                          fields: {
                            point: {
                              type: PointInputVue,
                              attrs: {
                                label: "Amount",
                                hideLabel: true,
                                required: true,
                                items: [50000, 100000, 200000, 500000, 1000000, 2000000],
                                tenantSetting: this.tenantSetting,
                              },
                              ext: {
                                defaultValue: 1,
                              },
                            },
                            paymentMethod: {
                              type: PaymentMethodInputVue,
                              attrs: {
                                label: "Payment Method",
                                hideLabel: true,
                                required: true,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              buttons: {
                cancel: {
                  ext: {
                    hidden: true,
                  },
                },
                save: {
                  attrs: {
                    block: true,
                    text: false,
                  },
                  content: {
                    text: "Pay",
                  },
                  states: {},
                  on: {
                    async xClick({ self }) {
                      const dialog = self.context();
                      // const { tenantSetting } = dialog.context().context();
                      const form = dialog.contentContainer.value;
                      const data = form.getData();
                      let apiName = "";
                      switch (data.paymentMethod.type) {
                        case "vnpay":
                          apiName = "integrationVnpay";
                          break;
                        case "momo":
                          apiName = "integrationMomo";
                          break;
                        default:
                          apiName = "integrationVnpay";
                          break;
                      }

                      const amount = data.point

                      self.loading.value = true;
                      const result = await coreApiClient.call(
                        apiName,
                        "createPaymentUrl",
                        {
                          amount,
                          id : data.paymentMethod.id,
                          device : "web",
                        }
                      );
                      self.loading.value = false;
                      if (result) {
                        location.href = result;
                      }
                    },
                  },
                },
              },
            },
          },
        },
      };
    },
  },
  created() {
    this.initialize();
  },
});
