





































import Vue from "vue";
export default Vue.extend({
  props: ["items", "value", "tenantSetting"],
  data() {
    return {};
  },
});
