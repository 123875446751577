




























import Loading from "@/components/Loading.vue";
import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
export default Vue.extend({
  props: ["value"],
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  methods: {
    getImgUrl(pic) {
      return require("@/assets/" + pic);
    },
  },
  async created() {
    try {
      this.loading = true;
      const { items } = await coreApiClient.call("paymentMethods", "findAll", {
        limit: -1,
      });
      this.items = items;
      this.$emit("input", items[0]);
    } finally {
      this.loading = false;
    }
  },
});
