import { render, staticRenderFns } from "./PointInput.vue?vue&type=template&id=7caa4d2e&scoped=true&"
import script from "./PointInput.vue?vue&type=script&lang=ts&"
export * from "./PointInput.vue?vue&type=script&lang=ts&"
import style0 from "./PointInput.vue?vue&type=style&index=0&id=7caa4d2e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7caa4d2e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCol,VIcon,VRow,VSheet})
